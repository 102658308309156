body {
  font-family: "Poppins", sans-serif;
  line-height: 16px;
}

.left-menu li a.active {
  background-color: #3274e5;
  font-weight: 700;
}
.ant-pagination {
  li {
    margin: 0;
  }
  .ant-pagination-item-active {
    background-color: #3876e3;
    border-color: #3876e3;
    color: #fff;
  }
}

.anticon {
  vertical-align: 2px;
}

.cursor-pointer {
  cursor: pointer;
}

.legend {
  display: flex;
  align-items: center;
  justify-content: center;
  hr {
    display: block;
    flex: 1;
    margin: 0px 30px;
    height: 1px;
    background: #ddd;
    &.left {
      margin-left: 0px;
    }

    &.right {
      margin-right: 0px;
    }
  }
}

.required {
  color: red;
}